import React, { Suspense } from "react"; // Import Suspense
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import useSectionHook from "./hooks/SectionHook";
import Loader from "./components/loader/Loader";

// Lazy load components
const Layout = React.lazy(() => import("./components/Layout/layout"));
const DetailsPage = React.lazy(() => import("./components/Details/details"));
const Home_page = React.lazy(() => import("./components/Home_page/home"));
const Login_page = React.lazy(() => import("./pages/Login/login_page"));
const Settings = React.lazy(() => import("./components/Settings/settings"));
const VideoPlayer = React.lazy(() => import("./components/Player/player"));
const FilterPage = React.lazy(() => import("./components/Filter/FilterPage"));
const AccountPage = React.lazy(() =>
  import("./components/Account/AccountPage")
);
const Subscription = React.lazy(() =>
  import("./components/Account/Subscription")
);
const AccountLink = React.lazy(() =>
  import("./components/AccountLink/AccountLink")
);
const Devices = React.lazy(() => import("./components/Devices/Devices"));

const App = () => {
  const {
    listData,
    selectedSectionID,
    sectionData,
    handleSectionClick,
    handleDetails,
    error,
  } = useSectionHook();

  return (
    <Suspense style fallback={<Loader />}>
      <div style={{ background: "#191919" }}>
        <Routes>
          <Route
            path="/"
            element={
              <Layout
                selectedSectionID={selectedSectionID}
                listData={listData}
                handleSectionClick={handleSectionClick}
                error={error}
              />
            }
          >
            <Route
              index
              element={
                <Home_page
                  selectedSectionID={selectedSectionID}
                  sectionData={sectionData}
                  handleDetails={handleDetails}
                />
              }
            />
            <Route
              path="details/:type/:id"
              element={<DetailsPage handleDetails={handleDetails} />}
            />
            <Route path="settings" element={<Settings />} />
            <Route path="login" element={<Login_page />} />
            <Route path="player/:type/:id" element={<VideoPlayer />} />
            <Route
              path="search"
              element={<FilterPage handleDetails={handleDetails} />}
            />
            <Route
              path="accountPage"
              element={<AccountPage handleDetails={handleDetails} />}
            />
            <Route path="subscribe" element={<Subscription />} />
            {/* Add a fallback route for 404 */}
            <Route path="*" element={<Home_page />} />
            <Route path="link" element={<AccountLink />} />
            <Route path="devices" element={<Devices />} />
          </Route>
        </Routes>
      </div>
    </Suspense>
  );
};

export default App;
