import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="full-screen-overlay">
      <div className="dot-loader">
        <div className="dot-item" id="dot-one"></div>
        <div className="dot-item" id="dot-two"></div>
        <div className="dot-item" id="dot-three"></div>
        <div className="dot-item" id="dot-four"></div>
      </div>
    </div>
  );
};

export default Loader;
